import styled from 'styled-components';

export const Text = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #333333;
    margin-left: 10px;
    @media (max-width: 1024px) {
      font-size:16px;
    }
`;

export const TextContainer = styled.div`
    display: inline;
    align-items:center;
`;
