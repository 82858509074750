import React from 'react';
import Heading from "../Heading";
import { ReactComponent as FolderClosedIcon } from "../../Assets/Images/Folder_Closed.svg";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from 'moment';
import { FolderOpenOutlined } from '@ant-design/icons';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const FlexWidget = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
`;
/*const AnnotationsCount = styled.div`
color: #3E5294;
font-size: 10px;
padding:15px;
`;*/
const JobName = styled.div`
color: #333;
`;

const ListItem = styled.div`
border-bottom: 1px solid #C4C4C4;
margin-bottom: 20px;
display: flex;
width: 100%;
justify-content: space-between;
`;

const TimeStamp = styled.div`
color: #81CDBA;
font-size:10px;
margin-top:3px;
margin-bottom:16px;
`;

const ListAnnotateJobs = ({ annotateJobs, lastAsset, activeJob }) => (
  <>
    <FlexWidget>
      <Heading>My Annotation Jobs</Heading>
      {/*<SubHeading>Assets Annotated</SubHeading>*/}
    </FlexWidget>
    <div style={{ height: 'calc(65vh - 72px)', overflowY: 'scroll'}}>
      {annotateJobs.length > 0 ? annotateJobs.map((job) => {
          const date = job.LastModifiedTime && moment(job.LastModifiedTime).format('d MMM yyyy');
          const allLabels = job.LabelCounters || {};
          return (
            <ListItem key={job.CreationTime}>
              <div>
                <div style={{display: 'flex'}}>
                  {job.LabelingJobName == activeJob ? <FolderOpenOutlined style={{ marginRight: '5px', color: '#EA203E', fontSize: '22px' }} /> : <FolderClosedIcon style={{marginRight: 10}}  /> }
                  <Link to={`/annotate/${job.LabelingJobName}`}><JobName>{job.LabelingJobName}</JobName></Link>
                  {lastAsset && lastAsset.hasOwnProperty(job.LabelingJobName) && <Link to={`/annotate/${job.LabelingJobName}/${lastAsset[job.LabelingJobName]}`}
                    className="dpf-continue-editing"><PlayArrowIcon />continue</Link>}
                </div>
                <TimeStamp>Last active {date} </TimeStamp>
              </div>
              {/*<AnnotationsCount>{allLabels.TotalLabeled || 0}/{allLabels.FailedNonRetryableError || allLabels.Unlabeled || 0}</AnnotationsCount>*/}
            </ListItem>
          )
        })
        : <p>No Jobs</p>}
    </div>
  </>
);

export default ListAnnotateJobs;