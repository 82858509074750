export const ENV = {
  DEV: 'DEV',
  UAT: 'UAT',
  PRODUCTION: 'PRODUCTION',
};

const ENV_ORIGINS = {
  [ENV.DEV]: [
    'http://localhost:3000', 'https://master.d25v0ga3gjif3f.amplifyapp.com/'
  ],
  [ENV.UAT]: [
    'https://master.dmyd7miobln9d.amplifyapp.com/',
  ],
  [ENV.PRODUCTION]: [
    'https://master.dmyd7miobln9d.amplifyapp.com/',
  ],
};

const startsWith = (string) => (beginning) => (new RegExp(`^${beginning}`)).test(string);

export const getEnv = (url = window.location.href) => Object.keys(ENV_ORIGINS).find((key) => ENV_ORIGINS[key].find(startsWith(url))) || ENV.DEV;

export const getCurrentOrigin = ENV_ORIGINS[getEnv()][0];
