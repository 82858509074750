import React from "react";
import styled from 'styled-components'

const Title = styled.h4`
color: #EA203E;
font-size:14px;
font-weight:bold;`;

const Title2 = styled.h4`
color: #3E5294;
font-size:12px;
font-weight:bold;`;

const Heading = ({ children }) => {
  return (
    <Title>{children}</Title>
  )
};
export const SubHeading = ({ children }) => {
  return (
    <Title2>{children}</Title2>
  )
};
export default Heading;
