import { Auth } from "aws-amplify";
import {
  DATA_UPLOADER_USER_GROUP,
  DATA_ANNOTATOR_USER_GROUP,
  DATA_VIEWER_USER_GROUP,
} from "./constants";

export async function getUserGroups() {
  const user = await Auth.currentAuthenticatedUser();
  return user?.signInUserSession?.accessToken?.payload["cognito:groups"];
}

export async function isUploader() {
  const user = await Auth.currentAuthenticatedUser();
  const usergroup =
    user?.signInUserSession?.accessToken?.payload["cognito:groups"];
  if (usergroup) {
    if (Array.isArray(usergroup)) {
      if (usergroup.includes(DATA_UPLOADER_USER_GROUP)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function isViewer() {
  const user = await Auth.currentAuthenticatedUser();
  const usergroup =
    user?.signInUserSession?.accessToken?.payload["cognito:groups"];
  if (usergroup) {
    if (Array.isArray(usergroup)) {
      if (usergroup.includes(DATA_VIEWER_USER_GROUP)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function isAnnotator() {
  const user = await Auth.currentAuthenticatedUser();
  const usergroup =
    user?.signInUserSession?.accessToken?.payload["cognito:groups"];
  if (usergroup) {
    if (Array.isArray(usergroup)) {
      if (usergroup.includes(DATA_ANNOTATOR_USER_GROUP)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}
