import React from 'react';
import { Link } from "react-router-dom";
import { Button, Form, Input } from "antd";

const ForgotForm = ({ handleSubmit, loading}) => {
  return (
    <>
      <div className="link info">
        Enter your email associate with your account.
      </div>
      <div className="login-form">
        <div className="">
          <Form onFinish={(values) => handleSubmit(values)} className="login-form">
            Email
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please provide your email!'
                }
              ]}
            >
              <Input
                type='text'
                className='cred-input'
              />
            </Form.Item>
            <Form.Item className="text-center mb-2">
              <Button
                type='submit'
                className="signin-button"
                loading={loading}
                htmlType="submit"
              >
                Recover
              </Button>
            </Form.Item>
            <Form.Item className="text-center mb-2">
              <Link className="link" to="/login">
                Already have an account? Sign in
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
)};

export default ForgotForm;
