import * as React from "react";
import { withRouter } from "react-router-dom";
import { Layout, notification } from "antd";

/** App Theme */
import { colors } from "../../Themes/Colors";


/** App contexts */
import { Context } from "../../Contexts/users";

/** App Components */
import BreadCrumb from "../../Components/breadcrumb";



const DashBoard = (props) => {

  const { logout } = React.useContext(Context);

  const handleLogout = async () => {
    try {
      logout();
    } catch (err) {
      notification.error({ message: err.message });
    }
  };

  return (
    <Layout className="cover" id="app-header">

      <Layout>
        <Layout.Header style={{ background: colors.white, padding: 0 }}>
          {props.pageTitle ? <div style={{ padding: '0 20px' }}>{ props.pageTitle }</div> : <BreadCrumb />}
        </Layout.Header>
        <Layout.Content
          style={{
            margin: "24px 16px",
            padding: 24,
            background: colors.white,
            minHeight: 280,
            height: '65vh'
          }}
        >
          {props.children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default withRouter(DashBoard);
