import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import "aws-sdk/clients/sagemaker";

import Annotator from "../../annotator";
import { useHistory, useParams, Link } from "react-router-dom";
import { notification, Spin, Modal, Button } from "antd";
import { LoadingOutlined, WarningOutlined } from "@ant-design/icons";

import {
  splitS3Url,
  getManifestData,
  filterInputBucket,
  getGlobalJobs,
} from "./utils";
import { outputManifest } from "./outputManifest";
import { labels } from "./labels";

import AnnotatorContainer from "../../Containers/AnnotatorContainer";
import { ReactComponent as BackIcon } from "../../Assets/Images/Back.svg";
import { TextContainer, Text } from "../Styled/Utils";
import FullSreenLoader from "../Loader";
import SearchImageIndex from "./SearchImageIndex";
import { fullScreenFlag } from "../../Utils/featureFlag";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, textAlign: "center" }} spin />
);

const { confirm } = Modal;

const AnnotateTool = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  const [images, setImages] = useState([{ regions: [] }]);

  const [imageRegions, setImageRegions] = useState([]);

  const [outputBucket, setOutputBucket] = useState("");

  const [inputBucket, setInputBucket] = useState("");

  const [assetMeta, setAssetMeta] = useState({});

  const [globalJobs, setGlobalJobs] = useState({});

  const [enableSubmit, setEnableSubmit] = useState(false);

  const [goToIndex, setGoToIndex] = useState(false);

  const jobParams = useParams();

  const history = useHistory();

  // set FullScreen
  const fullscreen = () => {
    document.getElementsByTagName("body")[0].classList = "fullScreen";
    setFullScreen(true);
  };
  // remove FullScreen
  const removeFullscreen = () => {
    document.getElementsByTagName("body")[0].classList = "";
    setFullScreen(false);
  };

  const prepareManifestData = (listUrl) => {
    // Filter manifest text response and return list.
    let _listUrl = listUrl.split('{"source-ref":"');
    let resourseList = [];

    _listUrl.map((list, index) => {
      let _url = list.split('"}');
      if (_url.length === 2) {
        let _name = _url[0].split("/");
        resourseList.push({
          jobName: jobParams.job,
          key: index,
          name: _name.pop(),
          src: _url[0],
          regions: [],
        });
      }
      return list;
    });
    setImages(resourseList);
  };

  const getJobManifest = async (bucketUrl) => {
    // Get Manifest file as promise.

    const s3 = new AWS.S3({ apiVersion: "2020-11-09" });

    let s3ObjectUrl = splitS3Url(bucketUrl);

    let params = {
      Bucket: s3ObjectUrl.groups.bucket,
      Key: s3ObjectUrl.groups.key,
    };

    let promise = s3.getSignedUrlPromise("getObject", params);

    await promise
      .then(
        async function (url) {
          let urlData = await getManifestData(url);
          await prepareManifestData(urlData);
        },
        function (err) {
          // TODO Handle Error
        }
      )
      .catch(() => notification.error({ message: "Resource not found." }));
  };

  const getJobAssetMeta = async (outputBucket) => {
    const s3 = new AWS.S3({ apiVersion: "2020-11-09" });

    let jsonObjectUrl = splitS3Url(outputBucket);

    if (jsonObjectUrl) {
      let jsonObjectKey = `${jsonObjectUrl.groups.key}${jobParams.job}/${jobParams.job}-meta.json`;

      let _params = { Bucket: jsonObjectUrl.groups.bucket, Key: jsonObjectKey };

      let jsonSignedUrl = s3.getSignedUrl("getObject", _params);

      await fetch(jsonSignedUrl)
        .then((response) => response.json())
        .then((data) => {
          setAssetMeta(data);
        })
        .catch(() => setAssetMeta({}));
    }
  };

  const describeLabelingJob = async (jobParams) => {
    const sagemaker = new AWS.SageMaker();

    const params = {
      LabelingJobName: jobParams.job /* required */,
    };

    await sagemaker.describeLabelingJob(params, async function (err, data) {
      if (err) {
        // an error occurred
        notification.error({ message: err.message });
      } else {
        filterInputBucket(
          data.InputConfig.DataSource.S3DataSource.ManifestS3Uri,
          setInputBucket
        );
        setOutputBucket(data.OutputConfig.S3OutputPath);

        await getJobManifest(
          data.InputConfig.DataSource.S3DataSource.ManifestS3Uri
        );
      }
    });
  };

  const getImageData = async (outputBucket) => {
    const s3 = new AWS.S3({ apiVersion: "2020-11-09" });

    let jsonObjectName = jobParams.asset.split(".");
    jsonObjectName = `${jsonObjectName[0]}.json`;

    let jsonObjectUrl = splitS3Url(outputBucket);

    if (jsonObjectUrl) {
      let jsonObjectKey = `${jsonObjectUrl.groups.key}${jobParams.job}/annotations/unconsolidated-annotation/output/${jsonObjectName}`;

      let _params = { Bucket: jsonObjectUrl.groups.bucket, Key: jsonObjectKey };

      let jsonSignedUrl = s3.getSignedUrl("getObject", _params);

      await fetch(jsonSignedUrl)
        .then((response) => response.json())
        .then((data) => {
          setImageRegions([...data.regions]);
        })
        .catch(() => setImageRegions([]));
    }
  };

  const saveImage = async (params, base64PngData) => {
    setIsLoading(true);

    let s3 = new AWS.S3({ apiVersion: "2020-11-09" });

    let bucketKey = splitS3Url(outputBucket);

    let _image = base64PngData.split("base64,");
    let imageBuffer = new Buffer(_image[1], "base64");

    let resourceName = params.name.split(".");

    let jsonBlob = new Blob(
      [
        JSON.stringify(
          {
            dimen: params.dimen,
            jobName: params.jobName,
            label: params.label,
            name: params.name,
            pixelSize: params.pixelSize,
            regions: params.regions,
            src: params.src,
          },
          null,
          2
        ),
      ],
      { type: "application/json" }
    );

    const _paramsImage = {
      Body: imageBuffer,
      Bucket: `${bucketKey.groups.bucket}/${bucketKey.groups.key}${params.jobName}/annotations/consolidated-annotation/output`,
      Key: `${resourceName[0]}.png`,
    };

    const _paramsJson = {
      Body: jsonBlob,
      Bucket: `${bucketKey.groups.bucket}/${bucketKey.groups.key}${params.jobName}/annotations/unconsolidated-annotation/output`,
      Key: `${resourceName[0]}.json`,
    };

    let now = new Date();

    assetMeta[params.name] = {
      name: params.name,
      updatedAt: now.toISOString(),
      numberOfLabels: params.regions.length,
    };

    assetMeta["lastAsset"] = {
      name: params.name,
      updatedAt: now.toISOString(),
    };

    setAssetMeta(assetMeta?.lastAsset ? { ...assetMeta } : assetMeta);

    const jsonJobBlob = new Blob([JSON.stringify(assetMeta, null, 2)], {
      type: "application/json",
    });

    const _paramsJobMeta = {
      Body: jsonJobBlob,
      Bucket: `${bucketKey.groups.bucket}/${bucketKey.groups.key}${params.jobName}`,
      Key: `${params.jobName}-meta.json`,
    };

    await s3.putObject(_paramsJson, function (err) {
      if (err) {
        setIsLoading(false);
        notification.error({ message: "Couldn't save image data." });
      } else {
        // notification.success({ message: "Image data saved successfully." });
      }
    });

    await s3.putObject(_paramsJobMeta, function (err) {
      if (err) {
        setIsLoading(false);
        notification.error({ message: "Couldn't save job data." });
      } else {
        // notification.success({ message: "Job data saved successfully." });
      }
    });

    await s3.putObject(_paramsImage, function (err) {
      if (err) {
        setIsLoading(false);
        notification.error({ message: "Couldn't save image." });
      } else {
        setIsLoading(false);
        notification.success({ message: "Image saved successfully." });
      }
    });
  };

  const submitImage = async (params) => {
    setIsLoading(true);

    let consolidateManifest = [];

    let now = new Date();

    params.images.map((image) => {
      let resourceName = image.name.split(".");
      let outputManifestParams = {
        sourceRef: `${inputBucket}/${image.name}`,
        outputSourceRef: `${outputBucket}${image.jobName}/annotations/consolidated-annotation/output/${resourceName[0]}.png`,
        creationDate: now.toISOString(),
        jobName: image.jobName,
      };
      consolidateManifest.push(outputManifest(outputManifestParams));
      return image;
    });

    let s3 = new AWS.S3({ apiVersion: "2020-11-09" });

    let bucketKey = splitS3Url(outputBucket);

    let textBlob = new Blob([JSON.stringify(consolidateManifest, null, 1)], {
      type: "text/plain",
    });

    const _paramsManifest = {
      Body: textBlob,
      Bucket: `${bucketKey.groups.bucket}/${bucketKey.groups.key}${params.jobName}/manifests/output`,
      Key: "output.manifest",
    };

    await s3.putObject(_paramsManifest, function (err) {
      if (err) {
        setIsLoading(false);
        notification.error({ message: "Couldn't save job data successfully." });
      } else {
        // notification.success({ message: "Job submitted successfully." });
      }
    });

    globalJobs[params.jobName] = {
      name: params.jobName,
      completedAt: now.toISOString(),
    };

    setGlobalJobs(globalJobs);

    const jobBlob = new Blob([JSON.stringify(globalJobs, null, 1)], {
      type: "application/json",
    });

    const _paramsJobs = {
      Body: jobBlob,
      Bucket: `${process.env.REACT_APP_AWS_BUCKET}/global`,
      Key: "jobs.json",
    };

    await s3.putObject(_paramsJobs, function (err) {
      if (err) {
        setIsLoading(false);
        notification.error({ message: "Couldn't job save successfully." });
      } else {
        setIsLoading(false);
        notification.success({ message: "Job submitted successfully." });
        history.push("/annotate");
      }
    });
  };

  const showSubmitConfirm = (params) => {
    confirm({
      title: "",
      icon: <WarningOutlined />,
      content:
        "When you click submit button, you will lose the ability to edit the labels.",
      okText: "Submit",
      width: 500,
      bodyStyle: { height: "300px" },
      onOk() {
        submitImage(params);
      },
    });
  };

  const imageIndex = images.findIndex(
    (image) => image.name === jobParams.asset
  );

  useEffect(() => {
    describeLabelingJob(jobParams);
  }, []);

  useEffect(() => {
    getImageData(outputBucket);
    getJobAssetMeta(outputBucket);
  }, [jobParams, outputBucket]);

  useEffect(() => {
    getGlobalJobs()
      .then((completedJobs) => setGlobalJobs(completedJobs))
      .catch(() => setGlobalJobs({}));
  }, []);

  useEffect(() => {
    setEnableSubmit(Object.keys(assetMeta).length === images.length + 1);
  }, [images, assetMeta]);

  useEffect(() => {
    if (globalJobs.hasOwnProperty(jobParams.job)) {
      history.push("/annotate");
    }
  }, [globalJobs]);

  useEffect(() => {
    if (goToIndex > 0 && goToIndex <= (images && images.length)) {
      let _image = images && images[goToIndex - 1];
      history.push(`/annotate/${_image.jobName}/${_image.name}`);
    } else {
      if (goToIndex != 0) {
        notification.error({ message: `Asset ${goToIndex} index is invalid.` });
      }
    }
  }, [goToIndex]);

  return (
    <>
      <AnnotatorContainer
        pageTitle={
          <>
            <Link to={`/annotate/${jobParams.job}`}>
              <TextContainer>
                <BackIcon /> <Text>Annotate</Text>
              </TextContainer>
            </Link>
            <div>
              {fullScreenFlag && (
                <Button
                  style={{ marginRight: 10, marginTop: 9 }}
                  type="primary"
                  onClick={() => {
                    !fullScreen ? fullscreen() : removeFullscreen();
                  }}
                >
                  {fullScreen ? "Go Back" : "Full Screen"}
                </Button>
              )}
              <SearchImageIndex onClick={setGoToIndex} />
            </div>
          </>
        }
      >
        {images.length > 1 ? (
          <Annotator
            images={images}
            regionClsList={labels}
            jobName={jobParams.job}
            selectedImage={imageIndex >= 0 ? imageIndex : 0}
            selectedImageRegion={imageRegions}
            useHistory={() => history}
            renderError={(error) => {
              notification.error({ message: error });
            }}
            onSave={(selectImage, base64PngData) =>
              saveImage(selectImage, base64PngData)
            }
            isSubmitButtonEnabled={enableSubmit}
            onSubmit={(state) => showSubmitConfirm(state)}
          />
        ) : (
          <Spin indicator={antIcon} />
        )}
        {isLoading ? <FullSreenLoader /> : ""}
      </AnnotatorContainer>
    </>
  );
};

export default AnnotateTool;
