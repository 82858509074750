import React, {useState} from "react";
import { Link, useHistory } from "react-router-dom";

import {ServiceNameLayout} from '../../Components/sevice.name.component/'

import LoginForm from "../../Components/LoginForm";
import ForgotForm from "./Forgot";
import Loader from "../../Components/Loader";
import { Auth } from "aws-amplify";
import { notification } from "antd";

const ForgotPasswordContainer = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const handleSubmit = async (event) => {
    setLoading(true);
    try {
      await Auth.forgotPassword(event.email);
      history.push('/reset-password');
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  return (
    <div className="main-container">
      {loading && <Loader />}
      {passwordResetSuccess ? (
          <div className='promo-container'>
            <p className='big-text-white'>Great!</p>
            <p className='small-text-white'>You have successful recovered your account.</p>
            <Link to="/login">Go back to login.</Link>
          </div>
        )
        : (
          <>
            <ServiceNameLayout subtext/>
            <LoginForm>
              <ForgotForm
                handleSubmit={handleSubmit}
                resetSuccess={success}
              />
            </LoginForm>
          </>
        )}
    </div>
  )
};

export default ForgotPasswordContainer;

