import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Empty } from "antd";

/** App containers */

/** utills */
import { isUploader, isViewer, isAnnotator } from "../../Utils";

import { ServiceNameLayout } from "../sevice.name.component"

import { BoxWrapper } from "../box.wrapper"

import { CstomCard } from './custom.card'
import './styles.css'

import Upload from '../../Assets/Images/Upload_Icon.svg'
import Annotate from '../../Assets/Images/Annotate_Icon.svg'
import View from '../../Assets/Images/View_Icon.svg'



export default () => {
  const [loading, setLaoding] = React.useState(false);
  const [uploader, setUploader] = React.useState(false);
  const [viewer, setViewer] = React.useState(false);
  const [annotator, setAnnotator] = React.useState(false);

  async function getData() {
  }

  React.useEffect(() => {
    getData();
    (async function () {
      setLaoding(true);
      setUploader(await isUploader());
      setViewer(await isUploader());
      setAnnotator(await isAnnotator());
      setLaoding(false);
    }());
  }, []);

  return (
    <div className='cards-layout'>
      <ServiceNameLayout />
      <div className='nav-container'>
        <Row className='cards-wrapper' gutter={[48,16]}>
          {uploader && <Col span={8}>
            <BoxWrapper className={'padding-50'}>
              <Link to="/upload">
                <CstomCard
                  image={Upload}
                  title='Upload'
                />
              </Link>
            </BoxWrapper>
          </Col>}
          {viewer && <Col span={8}>
            <BoxWrapper className={'padding-50'}>
              <Link to="/view">
                <CstomCard
                  image={View}
                  title='View'
                />
              </Link>
            </BoxWrapper>
          </Col>}
          {annotator && <Col span={8}>
            <BoxWrapper className={'padding-50'}>
              <Link to="/annotate">
                <CstomCard
                  image={Annotate}
                  title='Annotate'
                />
              </Link>
            </BoxWrapper>
          </Col>}
          {
            !loading && !uploader && !annotator && !viewer &&
            <div className="w-100">
              <Empty description="You don't have any permissions yet, please contact your administrator!" />
            </div>
          }
        </Row>
      </div>
    </div>
  );
};
