import React from 'react'
import { Link } from "react-router-dom";
import { Form, Button, Input } from "antd";
/** Assets */
import Logo from '../../Assets/Images/logo.black.svg'

import PasswordIcon from '../../Assets/Images/Password_Icon.svg'
import UsernameIcon from '../../Assets/Images/Email_icon.svg'

import '../Logincontainer/login.css'

const {} = Input

export const NewFormFormComponent = ({ handleSubmit, loading }) => {

  const renderIcon= (icon)=>{
    return <img width='1em' height='1ex' className='pass-icon' src={icon}/>
  }

  return (
    <div className='main'>
      <img className="black-logo-width" src={Logo} />
      <Link className="link info" to="/signup">
        Please enter new password below.
      </Link>
      {/* <span className='info'>Sign In with your username and password.</span> */}
      <div className="login-form">
        <div className="">
          <Form onFinish={(values) => handleSubmit(values)} className="login-form">
            New Password
            <Form.Item
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: 'Please provide new password!'
                }
              ]}
            >
              <Input loading type="password" className='cred-input' prefix={renderIcon(UsernameIcon)}/>
            </Form.Item>
            Enter New Password Again!
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: 'Password do not match!'
                }
              ]}
            >
              <Input
                type='password'
                prefix={renderIcon(PasswordIcon)}
                className='cred-input'
              />
            </Form.Item>
            <Form.Item className="text-center mb-2">
              <Link className="link" to="/forgot-password">
                Forgot your password?
              </Link>
            </Form.Item>
            <Form.Item className="text-center mb-2">
              <Button
                type='submit'
                className="signin-button"
                loading={loading}
                htmlType="submit"
              >
                Sign In
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}