import React from "react";
import styled from 'styled-components'
import { Link as NavLink } from 'react-router-dom';

const Title = styled.h4`
color: #333333;
font-size:12px;
line-height:1;
margin-top: 10px;`;

const ImageIcon = styled.img`
max-height: 70px`;

const LinkBox = styled(NavLink)`
display: flex;
width: 100%;
flex-direction: column;
align-items: center;
justify-content: center;`;

const Link = ({ children, icon, to }) => {
  return (
      <LinkBox to={to} >
          <ImageIcon src={icon} alt={children} />
          <Title>{children}</Title>
      </LinkBox>
  )
};
export default Link;
