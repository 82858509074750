export const labels = [
    {
        cls:'Renal Artery',
        color:'#2ca02c'
    },
    {
        cls:'Renal Vein',
        color:'#1f77b4'
    },
    {
        cls:'Aorta',
        color:'#ff7f0e'
    },
    {
        cls:'Spleen',
        color:'#d62728'
    },
    {
        cls:'Adrenal Gland',
        color:'#9467bd'
    },
    {
        cls:'Uretar',
        color:'#8c564b'
    },
    {
        cls:'Adrenal Vein',
        color:'#e377c2'
    },
    {
        cls:'Kidney',
        color:'#7f7f7f'
    },
    {
        cls:'Lumbar Vein',
        color:'#bcbd22'
    },
    {
        cls:'Gonadal Vein',
        color:'#ff9896'
    },
]