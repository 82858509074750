import React, { useState } from "react";
import { Auth } from 'aws-amplify';
import LoginForm from "../../Components/LoginForm";
import { ServiceNameLayout } from "../../Components/sevice.name.component";
import { Button, Form, Input, notification } from "antd";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";

const ResetPasswordContainer = () => {
  const [loading, setLoading] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const handleSubmit = async (values) => {setLoading(true);
    try {

      await Auth.forgotPasswordSubmit(values.email, values.code, values.password);
      setPasswordResetSuccess(true);

    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message,
        placement: "topRight",
      });
    } finally {

      setLoading(false)
    }
  }
  return (
    <div className='main-container'>
      {loading && <Loader />}
      {passwordResetSuccess ? (
        <div className='promo-container'>
          <p className='big-text-white'>Great!</p>
          <p className='small-text-white'>You have successful recovered your account.</p>
          <Link to="/login">Go back to login.</Link>
        </div>
      )
      : (
        <>
      <ServiceNameLayout subtext/>
      <LoginForm>
        <div className="link info">
          Please enter the code sent via email.
        </div>
        <div className="login-form">
          <div className="">
            <Form onFinish={(values) => handleSubmit(values)} className="login-form">
              Email
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your email!'
                  }
                ]}
              >
                <Input
                  type='text'
                  className='cred-input'
                />
              </Form.Item>
              Code
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Please provide the code!'
                  }
                ]}
              >
                <Input
                  type='text'
                  className='cred-input'
                />
              </Form.Item>
              Password

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter new password!'
                  }
                ]}
              >
                <Input
                  type='password'
                  className='cred-input'
                />
              </Form.Item>

              <Form.Item className="text-center mb-2">
                <Button
                  type='submit'
                  className="signin-button"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
              <Form.Item className="text-center mb-2">
                <Link className="link" to="/login">
                  Already have an account? Sign in
                </Link>
              </Form.Item>
            </Form>
          </div>
        </div>
      </LoginForm>
        </>
      )}
    </div>
  )
};

export default ResetPasswordContainer;
