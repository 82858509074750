import styled from "styled-components";
import { Form } from "antd";

/** App Theme */
import { colors } from "../../Themes/Colors";

const FormWrapper = styled(Form)`
  max-width: 350px;
  border: 1px solid ${colors.grey};
  margin: 7% auto !important;
  padding: 42px 24px 40px !important;
  background: ${colors.white};
  box-shadow: 17px 15px 20px 15px #e3e3e3;
`;

export default FormWrapper;
