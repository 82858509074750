import React from 'react';
import Logo from "../../Assets/Images/logo.svg";

const PublicLayout = ({ children }) => {
  return (
    <div className='background'>
      <div className='custom-header'>
        <img src={Logo} alt="logo" />
      </div>
      {children}
    </div>
  )
};

export default PublicLayout;
