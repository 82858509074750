import React, { useEffect } from "react";
import { Auth } from "aws-amplify";

const Logout = () => {
  const signOut = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log('currentAuthenticatedUser', user);
      Auth.signOut().then(() => {
        window.location.reload();
      });
    } catch (e) {
      console.log('User not logged in. Error: ', e, '. Action: do nothing');
    }
  }
  useEffect(() => {
    signOut();
  }, []);
  return null;
};

export default Logout;
