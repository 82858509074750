import AWS, { config } from "aws-sdk";
import "aws-sdk/clients/sagemaker";

export const splitS3Url = (objectUrl) => {
  // Split bucket name and key from s3 object url.
  let s3UrlRe = /[s|S]3:\/\/(?<bucket>[^\/]*)\/(?<key>.*)/;
  return s3UrlRe.exec(objectUrl);
};

export const getManifestData = async (manifestUrl) => {
  // Fetch manifest file and convert into text.
  let response = await fetch(manifestUrl);
  if (response.status === 200) {
    // format stream data in text format.
    return await response.text();
  }
  return [];
};

export const filterInputBucket = (s3Url, callback) => {
  s3Url = s3Url.split("/");
  s3Url.pop();
  callback(s3Url.join("/"));
};
export const getUserJobs = (user, allJobs = []) => {
  const jobs = [];
  const group =
    user?.signInUserSession?.accessToken?.payload["cognito:groups"] || [];
  const jobGroups = allJobs.map(getTeamNamesFromWorkArn);
  console.log(allJobs, "allJobs");
  console.log(group, "group");
  console.log(jobGroups, "jobGroups");
  jobGroups.forEach((g, i) => {
    const searchForGroup = group.find((group) => group === g);
    if (searchForGroup) {
      jobs.push(allJobs[i]);
    }
  });
  return jobs;
};

export const getGlobalJobs = async () => {
  const s3 = new AWS.S3({ apiVersion: "2020-11-09" });

  let _params = {
    Bucket: `${process.env.REACT_APP_AWS_BUCKET}`,
    Key: "global/jobs.json",
  };

  let jsonSignedUrl = s3.getSignedUrl("getObject", _params);
  return await fetch(jsonSignedUrl)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(() => console.log("error in fetching jobs"));
};

export const getGlobalVideos = async () => {
  const s3 = new AWS.S3({ apiVersion: "2020-11-09" });

  let _params = {
    Bucket: `${process.env.REACT_APP_AWS_BUCKET}`,
    Key: "global/videos.json",
  };

  let jsonSignedUrl = s3.getSignedUrl("getObject", _params);
  return await fetch(jsonSignedUrl)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(() => console.log("error in jobs video"));
};

export const filterCompletedJobs = async (jobs) => {
  const globalJobs = await getGlobalJobs();
  return Promise.resolve(
    jobs.filter((j) => {
      return !globalJobs[j.LabelingJobName];
    })
  );
};

export const getTeamNamesFromWorkArn = (jobs) => {
  let nameOfTheGroup = jobs.WorkteamArn.split("/");
  nameOfTheGroup = nameOfTheGroup[nameOfTheGroup.length - 1];
  if (nameOfTheGroup === "RediMinds") {
    nameOfTheGroup = "sagemaker-groundtruth-user-group";
  }
  return nameOfTheGroup;
};

export const getJobs = async () => {
  const { region, credentials } = config;
  const options = {
    region: region,
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
  };
  const sagemaker = new AWS.SageMaker(options);
  // StatusEquals: 'InProgress'
  const params = {};
  try {
    const { LabelingJobSummaryList } = await sagemaker
      .listLabelingJobs(params)
      .promise();
    return LabelingJobSummaryList;
  } catch (e) {
    console.log("error in fetching jobs", e);
  }
};

export const getSignedUrl = (activeImageSrc) => {
  const s3 = new AWS.S3({ apiVersion: "2020-11-09" });
  let bucketKey = splitS3Url(activeImageSrc);
  if (bucketKey) {
    let params = { Bucket: bucketKey.groups.bucket, Key: bucketKey.groups.key };
    return s3.getSignedUrl("getObject", params);
  }
  return activeImageSrc;
};

export const formatAssetName = (name) => {
  name = name.split(".");
  name = name[0].split("_");
  name.shift();
  return name.join("");
};
