/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:242081d3-57a4-4fe6-a1a6-7f7709d1f767",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_uPO9MT2yc",
    "aws_user_pools_web_client_id": "4gtpet2lcuq3mfoe365860ctkf",
    "oauth": {
        "domain": "ground-truth-factory-production.auth.us-east-2.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://fp2rexsegfhltbrhg2prtobbty.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "groundtruthprodapp-20220303232906-hostingbucket-gtfprod",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://dzo3wtw9e4vy4.cloudfront.net"
};


export default awsmobile;
