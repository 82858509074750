import React from "react";
import styled from 'styled-components'
import { Col, Row } from "antd";

const Title = styled.h4`
color: #333333;
font-size:12px;
line-height:1;
margin: 0;
@media (max-width: 1024px) {
  font-size: 9px;
}`;

const Count = styled.div`
color: #3E5294;
font-size:48px;
line-height:1;
font-weight: bold;`;

const ImageIcon = styled.img`
max-height: 70px;
@media (max-width: 1200px) {
  width: 40px;
}`;

const Stat = ({ children, icon, count }) => {
  return (
      <div style={{ height: 70, alignItems: 'flex-end'}}>
        <Row gutter={20} align="bottom">
          <Col span={6}>
            <ImageIcon src={icon} alt={children} />
          </Col>
          <Col span={14}>
            <Title>{children}</Title>
            <Count>{count}</Count>
          </Col>
        </Row>
      </div>
  )
};
export default Stat;
