import React from 'react';
import Logo from "../../Assets/Images/logo.black.svg";

const LoginForm = ({ children }) => {
  return (
    <div className='main'>
      <img className="black-logo-width" src={Logo} alt="logo" />
      {children}
    </div>
  )
};

export default LoginForm;
