import React from "react";
import { Card, Col, Row, Empty, Spin, message } from "antd";
import isEmpty from "lodash/isEmpty";

// assets
import { ReactComponent as FileIcon } from "../../Assets/Images/Play_icon.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/Delete_Icon.svg";
/** App containers */

import AWS from "aws-sdk";
import { Auth } from "aws-amplify";

import "./view.page.style.css";
import DashBoard from "../../Containers/Dashboard";
import QuickLinks from "../QuickLinks";
import styled from "styled-components";
import { Text as TitleText } from "../Styled/Utils";

const Text = styled.div`
  font-size: 12px;
`;
const { Meta } = Card;

export default () => {
  const [files, setfiles] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const getUsersFiles = async () => {
    setIsLoading(true);
    let credentials = await Auth.currentUserInfo();
    if (credentials) {
      let s3 = new AWS.S3({ apiVersion: "2020-11-09" });
      let params = {
        Bucket: `${process.env.REACT_APP_AWS_BUCKET}` /* required */,
        // Delimiter: 'STRING_VALUE',
        // EncodingType: url,
        // ExpectedBucketOwner: 'STRING_VALUE',
        // Marker: ,
        // MaxKeys: 'NUMBER_VALUE',
        Prefix: `${credentials.username}`,
        // RequestPayer: requester
      };
      s3.listObjects(params, function (err, data) {
        if (err) console.log(err, err.stack);
        // an error occurred
        else setfiles(data.Contents); // successful response
        setIsLoading(false);
      });
    }
  };
  const deleteImage = async (file) => {
    let s3 = new AWS.S3({ apiVersion: "2020-11-09" });
    let deleteParams = {
      Bucket: `${process.env.REACT_APP_AWS_BUCKET}`,
      Key: file.Key,
    };
    s3.deleteObject(deleteParams, (err) => {
      if (!err) {
        const currentfiles = [...files];
        const newArr = currentfiles.filter((el) => el.Key !== file.Key);
        setfiles([...newArr]);
      }
    });
  };

  const viewImage = async (file) => {
    let s3 = new AWS.S3({ apiVersion: "2020-11-09" });
    let viewParams = {
      Bucket: `${process.env.REACT_APP_AWS_BUCKET}`,
      Key: file.Key,
    };
    s3.getSignedUrl("getObject", viewParams, (err, url) => {
      if (!err) {
        window.open(url, "_blank");
      } else {
        message.error("Error opening Asset");
      }
    });
  };

  React.useEffect(() => {
    getUsersFiles();
    // Storage.list("default", { level: "private" }) // for listing ALL files without prefix, pass '' instead
    //     .then((result) => setfiles(result))
    //     .catch((err) => console.log(err));
  }, []);

  return (
    <DashBoard pageTitle={<TitleText>View Files</TitleText>}>
      <div className="scrollable">
        <Row gutter={4}>
          {!isEmpty(files) ? (
            files.map((file) => (
              <Col key={file.Key} style={{ marginBottom: "15px" }} span={3}>
                {console.log("file", file)}
                <Card
                  hoverable
                  bodyStyle={{ padding: 5 }}
                  cover={<FileIcon onClick={() => viewImage(file)} />}
                  actions={[
                    <DeleteIcon onClick={() => deleteImage(file)} />,
                    // <EyeOutlined onClick={() => viewImage(file)} />,
                  ]}
                >
                  <Meta
                    style={{ fontSize: 10 }}
                    title={<Text>{file.Key}</Text>}
                    description={`${new Date(
                      file.LastModified
                    ).toLocaleDateString()} ${new Date(
                      file?.LastModified
                    ).toLocaleTimeString()} `}
                  />
                </Card>
              </Col>
            ))
          ) : (
            <Col className="w-100">
              {" "}
              {isLoading ? <Spin>.</Spin> : <Empty />}
            </Col>
          )}
        </Row>
      </div>
      <QuickLinks links={["upload", "annotate", "home"]} />
    </DashBoard>
  );
};
