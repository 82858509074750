import React, { useEffect, useState } from "react";
import { Upload, message } from "antd";
import { Row, Col } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import AWS from "aws-sdk";
import { Auth } from "aws-amplify";

/** App containers */
import UploadStats from "./UploadStats";
import QuickLinks from "../QuickLinks";
import { getUploadStats } from "../../Services/getStats";
import DashBoard from "../../Containers/Dashboard";
import UploadIcon from "../../Assets/Images/Upload_Icon.svg";
import RecentUploads from "../../Assets/Images/Recent_Uploads_Icon.svg";
import TotalUploads from "../../Assets/Images/Total_Uploads_Icon.svg";
import { Text } from "../Styled/Utils";

/**App components */
// import Prompt from "../Prompt";

const { Dragger } = Upload;
const pStats = [
  {
    icon: UploadIcon,
    count: 0,
    name: "Active Uploads",
  },
  {
    icon: RecentUploads,
    count: 0,
    name: "Recent Uploads",
  },
  {
    icon: TotalUploads,
    count: 0,
    name: "Total Uploads",
  },
];
const props = {
  name: "file",
  multiple: true,
  progress: {
    strokeColor: {
      "0%": "#108ee9",
      "100%": "#87d068",
    },
    strokeWidth: 3,
    format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
  },
};

export default () => {
  const [stats, setStats] = useState({ files: [] });
  const [pageStats, setPageStats] = useState(pStats);
  useEffect(() => {
    updateUploadstats();
  }, []);

  const updateUploadstats = () => {
    getUploadStats().then((data) => {
      if (data) {
        setStats(() => ({ files: data.files }));
        const newData = [...pageStats];
        newData[0].count = data.active;
        newData[1].count = data.recent;
        newData[2].count = data.total;
        setPageStats([...newData]);
      }
    });
  };
  const onUnload = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const handleUpload = async ({ onSuccess, onError, file }) => {
    window.addEventListener("beforeunload", onUnload, false);
    if (file) {
      let credentials = await Auth.currentUserInfo();
      let s3 = new AWS.S3({ apiVersion: "2020-11-09" });
      // call S3 to retrieve upload file to specified bucket
      let uploadParams = {
        Bucket: `${process.env.REACT_APP_AWS_BUCKET}/${credentials.username}`,
        Key: file.name,
        Body: file,
      };
      s3.upload(uploadParams)
        .on("httpUploadProgress", (evt) => {
          const uploaded = Math.round((evt.loaded / evt.total) * 100);
          const fil = stats.files;
          const b = fil.find((f) => evt.key.includes(f.name));
          b.progress = uploaded;
          setStats((state) => ({ ...state, files: fil }));
        })
        .send(function (err, data) {
          if (err) {
            console.log(err);
            onError();
            return;
          }
          console.log(data);
          updateUploadstats();
          onSuccess();
        });
    }
  };

  const removeFileHandler = async (file) => {
    let credentials = await Auth.currentUserInfo();
    let s3 = new AWS.S3({ apiVersion: "2020-11-09" });
    let deleteParams = {
      Bucket: `${process.env.REACT_APP_AWS_BUCKET}/${credentials.username}`,
      Key: file.name,
    };
    s3.deleteObject(deleteParams, (err, data) => {
      console.log(data);
    });
  };

  return (
    <DashBoard pageTitle={<Text>Upload Video</Text>}>
      <Row gutter={30}>
        <Col span={8}>
          <Dragger
            onRemove={removeFileHandler}
            customRequest={(e) => handleUpload(e)}
            beforeUpload={(e, s) => {
              const files = [...pageStats];
              files[0].count = s.length;
              setPageStats([...files]);
              setStats({ files: s });
            }}
            {...props}
            onChange={(info) => {
              const { status } = info.file;
              if (status === "done") {
                message.success(
                  `${info.file.name} file uploaded successfully.`
                );
                const files = [...pageStats];
                files[0].count = files[0].count !== 0 ? files[0].count - 1 : 0;
                if (files[0].count === 0) {
                  window.removeEventListener("beforeunload", onUnload, false);
                }
                setPageStats([...files]);
                // setSelectedfile(info?.file?.originFileObj)
              } else if (status === "error") {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from
              uploading company data or other brand files
            </p>
          </Dragger>
        </Col>
        <Col span={16}>
          <div style={{ borderLeft: "3px solid #81CDBA", paddingLeft: 30 }}>
            <UploadStats
              title="Upload Stats"
              pageStats={pageStats}
              stats={stats}
            />
            <QuickLinks links={["view", "annotate", "home"]} />
          </div>
        </Col>
      </Row>
    </DashBoard>
  );
};
