import * as React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

/** Container components */
import LoginContainer from "../Containers/Logincontainer";
import Cards from "../Components/cards";
import Upload from "../Components/upload";
import View from "../Components/view";
import Annotate from "../Components/annotate";
import AnnotateTool from "../Components/annotate/AnnotateTool";

/** private route component */
import PrivateRoute from "./PrivateRoute";
import AnnotatorJobs from "../Containers/AnnotatorJobs";
import ForgotPasswordContainer from "../Containers/ForgotPasswordContainer";
import ResetPasswordContainer from "../Containers/ResetPasswordContainer";
import CreatePasswordContainer from "../Containers/CreatePasswordContainer";
import PublicLayout from "../Containers/PublicLayout";
import Settings  from "../Components/Settings/";
import Logout from "../Containers/Logout";

const AppRouter = () => {
  return (
    <Router>
        <Switch>
          <Route exact={true} path="/login" render={(props) => <PublicLayout><LoginContainer {...props} /></PublicLayout>} />
          <Route exact={true} path="/forgot-password" render={(props) => <PublicLayout><ForgotPasswordContainer {...props} /></PublicLayout>} />
          <Route exact={true} path="/create-password" render={(props) => <PublicLayout><CreatePasswordContainer {...props} /></PublicLayout>} />
          <PrivateRoute exact={true} path="/dashboard" component={Cards} withoutLogo={true} />
          <PrivateRoute exact={true} path="/upload" component={Upload} />
          <PrivateRoute exact={true} path="/view" component={View} />
          <PrivateRoute exact={true} path="/annotate" component={Annotate} />
          <PrivateRoute exact path="/annotate/:job" component={AnnotatorJobs} />
          <PrivateRoute path="/settings" component={Settings} />
          <PrivateRoute exact={true} path="/annotate/:job/:asset" component={AnnotateTool} />
          <PrivateRoute exact={true} path="/" component={Cards} withoutLogo={true} />
          <PrivateRoute exact={true} path="/logout" component={Logout} withoutLogo={true} />
          <Route exact path="/reset-password" render={(props) => <PublicLayout><ResetPasswordContainer {...props} /></PublicLayout>} />
        </Switch>
        {/* <PrivateRoute exact={true} path="/" component={DashBoardContainer} /> */}
        {/* <Route exact={true} path="/signup" component={SignUpContainer} /> */}
        {/* <Route exact={true} path="/verify-code" component={ConfirmEmailContainer} /> */}
    </Router>
  );
};

export default AppRouter;
