import { Auth } from "aws-amplify";
import AWS from "aws-sdk";

const getUserFiles = async () => {
  try {
    const credentials = await Auth.currentUserInfo();
    const s3 = new AWS.S3({ apiVersion: "2020-11-09" });
    const params = {
      Bucket: `${process.env.REACT_APP_AWS_BUCKET}`,
      Prefix: `${credentials.username}`,
    };
    const { Contents } = await s3.listObjects(params).promise();
    return Contents;
  } catch (e) {
    console.error("Error in fetching files", e);
  }
};

const getUploadStats = async () => {
  try {
    const files = await getUserFiles();
    console.log(files, "files");
    let recent;
    if (files.length) {
      const today = new Date(Date.now()).getTime();
      recent = files.filter(
        (file) => new Date(file.LastModified).getTime() >= today - 604800000
      );
    }
    return {
      total: files?.length || 0,
      recent: recent?.length || 0,
      active: 0,
    };
  } catch (e) {
    console.error("Error in fetching stats", e);
  }
};

export { getUploadStats };
