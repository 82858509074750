import React  from "react";
import Heading from "../Heading";
import { Row, Col, Progress } from "antd";
import styled from 'styled-components';
import Stat from "../Stat/Stat";

const Text = styled.p`
font-size:12px;
`;
const Container = styled.div`
height: 36vh;
border-bottom: 1px solid #C4C4C4;
margin-bottom: 25px;
`;
const UploadStats = ({ stats, title, pageStats = [] }) => {
  return (
    <Container className="uploadStats">
      <Row>
        <Col span={24}>
          <Heading>{title}</Heading>
        </Col>
        {pageStats.map((st) => <Col key={st.name} span={8}>
          <Stat icon={st.icon} count={st.count}>
            {st.name}
          </Stat>
        </Col>)}
      </Row>
      <div style={{ marginTop: 20}}>
        <Row gutter={20}>
          {stats.files && stats.files.map(k => (
            <Col>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Progress key={k.name} type="circle" percent={k.progress} width={50} />
                <Text>{k.name}</Text>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  )
};

export default UploadStats;
