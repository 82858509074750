import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { ServiceNameLayout } from "../../Components/sevice.name.component";
import LoginForm from "../../Components/LoginForm";
import { Button, Form, Input, notification } from "antd";
import {ReactComponent as CheckIcon} from "../../Assets/Images/Check.svg";
import styled from 'styled-components';
import Loader from "../../Components/Loader";

const FormHeading = styled.div`
  font-size: 24px;
  margin-top: 20px;
`;
const FormSubHeading = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #C4C4C4;
`;
const HelperText = styled.div`
  color: #81CDBA;
  font-size:14px;
`;
const PasswordHelper = styled.div`
  margin-bottom: 10px;
`;


const CreatePasswordContainer = () => {
  const [loading, setLoading] = useState(false);
  const [changePasswordStatus, setChangePasswordStatus] = useState(false);
  const handleSubmit = async (fields) => {
    setLoading(true);
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        fields.oldPassword,
        fields.password
      );
      setChangePasswordStatus(true);

    } catch (e) {
      notification.error({
        message: "Error",
        description: e.message,
        placement: "topRight",
      })

    }
    finally {
      setLoading(false);
    }
  }
  const history = useHistory();
  return (
    <div className='main-container'>
      {loading && <Loader />}
      {!changePasswordStatus
      ? (
        <>
          <ServiceNameLayout subtext/>
          <LoginForm>
            <FormHeading>
              Change Password
            </FormHeading>
            <div className="link">
              Please enter your password below.
            </div>
            <div className="login-form">
              <div className="">
                <Form onFinish={(values) => handleSubmit(values)} className="login-form">
                  Old Password
                  <Form.Item
                    name="oldPassword"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your old password!'
                      }
                    ]}
                  >
                    <Input
                      type='password'
                      className='cred-input'
                    />
                  </Form.Item>
                  New Password
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter new password!'
                      }
                    ]}
                  >
                    <Input
                      type='password'
                      className='cred-input'
                    />
                  </Form.Item>{/*
              Enter New Password Again
              <Form.Item
                name="repeatPassword"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the code sent via email!'
                  }
                ]}
              >
                <Input
                  type='text'
                  className='cred-input'
                />
              </Form.Item>*/}
                  <PasswordHelper>
                    <HelperText><CheckIcon style={{ marginRight: 10 }} />Password must contain at least 8 characters.</HelperText>
                  </PasswordHelper>
                  <Form.Item className="text-center mb-2">
                    <Button
                      type='submit'
                      className="signin-button"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                    <Button
                      type='button'
                      onClick={() => history.goBack()}
                      className=""
                      htmlType="submit"
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </LoginForm>
        </>
        )
      : (
          <div className='promo-container'>
            <p className='big-text-white'>Great!</p>
            <p className='small-text-white'>You have successful changed your password.</p>
            <Link to="/">Go back to Home.</Link>
          </div>
        )}

    </div>
  )
};

export default CreatePasswordContainer;
