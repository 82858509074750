import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { filterCompletedJobs, getJobs, getUserJobs } from "./utils";

const useSageMakerJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [jobsLoading, setLoading] = useState(true);

  useEffect(() => {
    getJobs().then(allJobs => {
      Auth.currentAuthenticatedUser().then((data) => {
        const job = getUserJobs(data, allJobs);
        filterCompletedJobs([...job]).then(activeJobs => {
          setJobs([...activeJobs]);
        })
        .finally(() => setLoading(false));
      })
    })
  }, []);


  return [jobs, jobsLoading];
}
export default useSageMakerJobs;
