// @flow

import React, { memo, useEffect, useState } from "react"
import Container from "./Container"
import ListItemText from "@material-ui/core/ListItemText"
import isEqual from "lodash/isEqual"
import {Link,useParams } from 'react-router-dom';
import { List } from 'antd';
import { FolderOpenOutlined } from '@ant-design/icons';
const queryString = require('query-string');

export const ImageSelectorSidebarBox = ({ images, onSelect,state,useHistory }) => {
  const history = useHistory();
  const {job,asset} = useParams();
  const [currentPage, setCurrentPage] = useState(1);


  const handleRouteChange = () => {
      if (asset) {
        // Check if we have given asset in images store
        let imageIndex = images.findIndex(image => image.name === asset);

        if (imageIndex >= 0) {
          // Update current asset to asset matched
          setTimeout(() => {
            onSelect(images[imageIndex], imageIndex)
          }, 0);
        } else {
          // No asset found in images store, fallback to 1st image.
          history.push(images[0].name)
        }
      }
      else {
        // No asset is given, fallback to 1st image
        history.push(images[0].name)
      }
  }

  useEffect(() => {
    /**
       * Do this once just before component is mounted.
       * 1. Check if annotator has asset in search params
       * 2. Set the annotator selected image to matched image.
       * 3.Otherwise fallback to set first image
       * 4.Set first image of annotator as selected image if no asset name is given.
       *  */
    handleRouteChange();

    // Listen for back button navigation
    history.listen(() => {
       const { location: { pathname } } = history;
      let assetName = pathname.split('/')[3];
      if(assetName) {
        let imageIndex = images.findIndex(image => image.name === assetName);
        if(imageIndex >= 0) {
          onSelect(images[imageIndex], imageIndex)
        }else{
          onSelect(images[0], 0)
        }
      }
    });
  },[])
  const getPage = (activeImage = 0) => {
    if (parseInt(activeImage) % 15 === 0) {
      return parseInt(activeImage/15);
    }
    return parseInt((activeImage/15) + 1);

  };
  useEffect(()=> {
    try {
      const key = queryString.parse(history.location.search) && queryString.parse(history.location.search).key;
      if (!key && state.selectedImage) {
        setCurrentPage(getPage(state.selectedImage));
        return;
      }
      setCurrentPage(getPage(key));
    } catch (e) {
      console.log('error in updating pagination', e);
    }
  }, [history, state.selectedImage])


  const onChangePage = (page, pageSize) =>{
    setCurrentPage(page)
  }



  return (
    <Container
      title={job || 'Job Name'}
      subTitle={`(${images.length})`}
      icon={<FolderOpenOutlined style={{ color: '#EA203E', fontSize: '22px' }} />}
    >
      <List
        className="dpfSidelist"
        pagination={{
          position: "bottom",
          showSizeChanger: false,
          pageSize: 15,
          current: currentPage,
          onChange: onChangePage
        }}
        dataSource={images}
        renderItem={(image, i) => (
          <Link
            to={`/annotate/${job}/${image.name}?key=${image.key}`}
            className={
              `imageListItem ${image.name === asset ? 'imageSelectItem' : ''}`
            }
            onClick={() => onSelect(image, i)}
            key={i}>
            <ListItemText
              primary={`${image.key}_${image.name}`}
            />
          </Link>
        )}
      />
    </Container>
  )
}

const mapUsedImageProps = (a) => [a.name, (a.regions || []).length, a.src]

export default memo(ImageSelectorSidebarBox, (prevProps, nextProps) => {

  return isEqual(
    prevProps.images.map(mapUsedImageProps),
    nextProps.images.map(mapUsedImageProps),
  ) && isEqual(
    prevProps.state.selectedImage,
    nextProps.state.selectedImage,
  )
 }

)
