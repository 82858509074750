import React from 'react';
import Logo from "../../Assets/Images/logo.svg";
import DPFLogo from "../../Components/Logo";
import { UsersDropdown } from "../../Components/dropdown";

const PrivateLayout = ({ children, withoutLogo }) => {
  return (
    <div className='background'>
      <div className='custom-header'>
        <img src={Logo} className="redimind-logo" alt="logo" />
        {!withoutLogo && <DPFLogo />}
        <UsersDropdown />
      </div>
      {children}
    </div>
  )
};

export default PrivateLayout;
