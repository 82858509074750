import * as React from "react";
import { withRouter } from "react-router-dom";
import { Layout, Menu, notification } from "antd";
import {
  HomeOutlined,
  LogoutOutlined,
  // UploadOutlined,
  // UnorderedListOutlined,
  // SelectOutlined
} from "@ant-design/icons";

/** App Theme */
import { colors } from "../../Themes/Colors";
import "./annotate.css";

/** App Constatns */
// import { AUTH_USER_TOKEN_KEY } from '../../Utils/constants';

/** App contexts */
import { Context } from "../../Contexts/users";

/** App Components */
import BreadCrumb from "../../Components/breadcrumb";
import { useEffect } from "react";

const AnnotatorContainer = (props) => {

  const { history } = props;
  const { logout } = React.useContext(Context);
  const [collapsed] = React.useState(false);

  const handleLogout = async () => {
    try {
      logout();
    } catch (err) {
      notification.error({ message: err.message });
    }
  };

  return (
    <Layout className={`cover ${props.className}`} id="app-header" style={{ marginTop: '30px'}}>
      <Layout>
        <Layout.Header style={{ background: colors.white, padding: 0 }}>
          {props.pageTitle ? <div style={{ padding: '0 20px' }}>{ props.pageTitle }</div> : <BreadCrumb />}
        </Layout.Header>
        <Layout.Content
          style={{
            margin: "24px 16px",
            padding: 24,
            background: colors.white,
            minHeight: 280,
          }}
        >
          {props.children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default withRouter(AnnotatorContainer);
