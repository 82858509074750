import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Logo = styled.div`
display: flex;
> a {
display: flex;
}
`;
const Heading = styled.div`
font-weight: 900;
font-size: 36px;
line-height: 42px;
margin-right: 10px;

color: #FFFFFF;
@media (max-width: 1024px) {
  font-size:18px;
}`;
const SubHeading = styled.div`
font-weight: 900;
font-size: 36px;
line-height: 42px;

color: #EA203E;
@media (max-width: 1024px) {
  font-size:18px;
}`;
const DPFLogo = () => {
  return (
    <Logo>
      <Link to="/">
        <Heading>Ground Truth</Heading>
        <SubHeading>Factory</SubHeading>
      </Link>
    </Logo>
  )
};

export default DPFLogo;
