export const outputManifest = (props) => {
    return {
        "source-ref": props.sourceRef,
        [`${props.jobName}-ref`]: props.outputSourceRef,
        [`${props.jobName}-ref-metadata`]:{
            "internal-color-map":{
                "0":{"class-name":"BACKGROUND","hex-color":"#ffffff","confidence":0},
                "1":{"class-name":"Renal Artery","hex-color":"#2ca02c","confidence":0},
                "2":{"class-name":"Renal Vein","hex-color":"#1f77b4","confidence":0},
                "3":{"class-name":"Aorta","hex-color":"#ff7f0e","confidence":0},
                "4":{"class-name":"Spleen","hex-color":"#d62728","confidence":0},
                "5":{"class-name":"Adrenal Gland","hex-color":"#9467bd","confidence":0},
                "6":{"class-name":"Ureter","hex-color":"#8c564b","confidence":0},
                "7":{"class-name":"Adrenal Vein","hex-color":"#e377c2","confidence":0},
                "8":{"class-name":"Kidney","hex-color":"#7f7f7f","confidence":0},
                "9":{"class-name":"Lumbar Vein","hex-color":"#bcbd22","confidence":0},
                "10":{"class-name":"Gonadal Vein","hex-color":"#ff9896","confidence":0}
            },
            "type": "groundtruth/semantic-segmentation",
            "human-annotated": "yes",
            "creation-date": props.creationDate,
            "job-name": `labeling-job/${props.jobName}`
        }
    }
}