import React from "react";
import { useHistory, Link } from "react-router-dom";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUser as getUserFromApi } from "../../graphql/queries";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Form, Spin, Button, notification, Col, Row, Input } from "antd";
import {
  GoogleOutlined,
  FacebookOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { LogInFormComponent } from "../../Components/login.form.component/login.form.component";

import { ServiceNameLayout } from "../../Components/sevice.name.component/";

/** Presentational */
import FormWrapper from "../../Components/Styled/FormWrapper";

/** App theme */
import { colors } from "../../Themes/Colors";

/** App constants */
import { AUTH_USER_TOKEN_KEY } from "../../Utils/constants";

/** Assets */
import RedimindsLogo from "../../Assets/Images/logo_Better.png";

import "./login.css";

/** App data */
import { Context } from "../../Contexts/users";
import { NewFormFormComponent } from "../NewPasswordContainer/NewPasswordContainer";
import AWS from "aws-sdk";
import { settingsFlag } from "../../Utils/featureFlag";
// type State = {
//   loading: boolean;
// };

const LoginContainer = (props) => {
  console.log(Auth);
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const [newPasswordForm, setNewPasswordForm] = React.useState(false);

  const { setUser } = React.useContext(Context);
  const [localUser, setLocalUser] = React.useState();

  const init = React.useCallback(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        history.push("/");
      })
      .catch(() => {});

    getUser().then((userData) => {
      console.log(userData);
    });
  }, [history]);

  React.useEffect(init, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }
  const handleNewPassword = (values) => {
    Auth.completeNewPassword(
      localUser, // the Cognito User Object
      values.newPassword // the new password
    )
      .then((a) => {
        const { history, location } = props;
        const { from } = location.state || {
          from: {
            pathname: "/",
          },
        };

        setUser(a);
        setLocalUser(a);
        history.push(from?.pathname);
      })
      .catch((b) => console.log("lll", b));
  };
  const getImage = async (Bucket, Key) => {
    const s3 = new AWS.S3({ apiVersion: "2020-11-09" });
    let params = {
      Bucket,
      Key,
    };
    const url = await s3.getSignedUrl("getObject", params);
    return url;
  };

  const setUserSuccess = (user, url) => {
    const { history, location } = props;
    if (url) {
      localStorage.setItem("userImage", url);
    }
    const { from } = location.state || {
      from: {
        pathname: "/",
      },
    };

    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      setNewPasswordForm(true);
      setLocalUser(user);
      setUser(user);
    } else {
      notification.success({
        message: "Succesfully logged in!",
        description: "Logged in successfully, Redirecting you in a few!",
        placement: "topRight",
        duration: 1.5,
      });

      setUser(user);
      setLocalUser(user);
      history.push(from?.pathname);
    }
  };

  const handleSubmit = (values) => {
    let { username, password } = values;

    setLoading(true);

    Auth.signIn(username, password)
      .then((user) => {
        console.log(user);
        if (settingsFlag) {
          API.graphql(graphqlOperation(getUserFromApi, { id: user.username }))
            .then((d) => {
              if (d.data.getUser) {
                const { name } = d.data.getUser;
                getImage(process.env.REACT_APP_AWS_BUCKET, name)
                  .then((url) => {
                    setUserSuccess(user, url);
                  })
                  .catch(() => {
                    setUserSuccess(user);
                  });
              } else {
                setUserSuccess(user);
              }
            })
            .catch(() => setUserSuccess(user));
        } else {
          setUserSuccess(user);
        }
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.message,
          placement: "topRight",
        });
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="main-container">
      <ServiceNameLayout subtext />
      {newPasswordForm ? (
        <NewFormFormComponent
          loading={loading}
          handleSubmit={handleNewPassword}
        />
      ) : (
        <LogInFormComponent loading={loading} handleSubmit={handleSubmit} />
      )}
    </div>
  );
};

export default LoginContainer;

/**
 * <Form onFinish={handleSubmit} className="login-form">
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: 'Please provide your username!'
            }
          ]}
        >
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please provide   your password!'
            }
          ]}
        >
          <Input.Password
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item className="text-center mb-2">
          {/* <Row type="flex" gutter={16}>
            <Col lg={24}>
              <Link style={{ float: 'right' }} className="login-form-forgot" to="/forgot-password">
                Forgot password
                </Link>
            </Col>
            <Col lg={24}>

            </Col>
          </Row>
          <Button
            style={{ width: '100%' }}
            type="primary"
            disabled={loading}
            htmlType="submit"
            size='large'
            className="login-form-button mb-2 p-0 rounded"
          >
            <div className="d-flex allign-center">
              <UnlockOutlined style={{ fontSize: '38px' }} />
              <div className="text-center w-100 p-1">{loading ? <Spin /> : 'Log in'}</div>
            </div>
          </Button>
        </Form.Item>
        <Row type="flex" gutter={16}>
          <Col lg={24}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={loading}
              size='large'
              onClick={() =>
                Auth.federatedSignIn({
                  provider: CognitoHostedUIIdentityProvider.Google,
                }).then((user) => {
                  console.log(user);
                })
              }
              className="login-form-button mb-2 p-0 rounded"
            >
              <div className="d-flex allign-center">
                <GoogleOutlined style={{ fontSize: '38px' }} />
                <div className="text-center w-100 p-1">{"Sign in with Google"}</div>
              </div>
            </Button>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={loading}
              size="large"
              onClick={() =>
                Auth.federatedSignIn({
                  provider: CognitoHostedUIIdentityProvider.Facebook,
                }).then((user) => {
                  console.log(user);
                })
              }
              className="login-form-button p-0 rounded"
            >
              <div className="d-flex">
                <FacebookOutlined style={{ fontSize: '38px' }} />
                <div className="text-center w-100 p-1">{"Sign in with Facebook"}</div>
              </div>
            </Button>
          </Col>
        </Row>
      </Form>
 */
