import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Image } from 'antd';
import { PlayCircleTwoTone, CloseSquareTwoTone } from '@ant-design/icons';

import Player from '../../../VideoPlayer/index';

import { getSignedUrl, getGlobalVideos } from "../../../Components/annotate/utils";

const VideoPreview = (props) =>{

    const params = useParams();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [S3URI, setS3URI] = useState(false);
    const [frameRate, setFrameRate] = useState(25)
    const [videoSignedUrl, setVideoSignedUrl] = useState(false);

    const [videos, setVidoes] = useState({});
    const [timeStart, setTimeStart] = useState(0);

    useEffect(() => {
        getGlobalVideos()
        .then((videos) => setVidoes(videos))
        .catch(() => setVidoes({}))
    }, []);

    useEffect(() => {
        if(videos.hasOwnProperty(params.job)){
            setS3URI(videos[params.job].S3URI)
            setVideoSignedUrl(getSignedUrl(videos[params.job].S3URI));
            setFrameRate(videos[params.job].frameRate)
            getPlayTimeInfo();
        }
    }, [videos])

    useEffect(() => {
        if(S3URI){
            setVideoSignedUrl(getSignedUrl(S3URI));
            getPlayTimeInfo();
        }
    }, [params])

    const getPlayTimeInfo = () =>{
        let frameNumber = params.asset.match(/([\d]+)/g);
        setTimeStart(parseInt(frameNumber[0])/frameRate);
    }
    
    return (
        <>
            {videos.hasOwnProperty(params.job) ? (<div className="dpf-video-preview">
                <Image src="error" fallback={getSignedUrl(props.state.images[props.state.selectedImage].src)} />
                <span className="dpf-video-preview-overlay"/>
                <PlayCircleTwoTone className="dpf-video-preivew-play" onClick={()=>setIsModalVisible(true)} />
            </div>) : null}
            {isModalVisible ? (<div className="dpf-video-preview-modal-overlay">
                <div className="dpf-video-preview-modal" onClick={()=>setIsModalVisible(false)}></div>
                <CloseSquareTwoTone className="dpf-video-player-close" onClick={()=>setIsModalVisible(false)}/>
                <div className="dpf-video-player-container">
                    <Player src={videoSignedUrl} timeStart={timeStart} className="dpf-video-player" />
                </div>
            </div>) : null}
        </>
    )
}

export default VideoPreview;