import React from 'react'

import { Menu, Dropdown, notification } from 'antd'

import ArrowDown from '../../Assets/Images/arrow_down_red.svg'
import Bar from '../../Assets/Images/Menu_Bar.svg'
import Close from '../../Assets/Images/Close.svg'
import Settings from '../../Assets/Images/Seetings_Icon.svg'
import Logout from '../../Assets/Images/LogOut.svg'

import { Context } from "../../Contexts/users";

import MockImage from '../../Assets/Images/doctor_mock.svg'

import './style.css'
import { Link } from "react-router-dom";
import { settingsFlag } from "../../Utils/featureFlag";

export const UsersDropdown = ({ content }) => {

    const { logout, user } = React.useContext(Context);

    const handleLogout = async () => {
        try {
            logout();
        } catch (err) {
            notification.error({ message: err.message });
        }
    };
    const [dropdownState, setDropdownState] = React.useState(false)

    const menu = (
        <div className='overlay'>
            <img className='close-button' src={Close} onClick={(e) => setDropdownState(false)} />
            <div className='menu-item'>
              <Link to={settingsFlag ? '/settings' : '/create-password'} onClick={() => setDropdownState(false)}>
                <img height={28} width={28} src={Settings} />
                <span className='menu-item-name'>SETTINGS</span>
              </Link>
            </div>
            <div className='menu-item' onClick={(e) => handleLogout()}>
                <img height={28} width={28} src={Logout} />
                <span className='menu-item-name'>LOGOUT</span>
            </div>
        </div>
    );
    return user? (
        <div className='account-info-container'>
          <div className="avatarContainer">
            <img className='avatar' src={localStorage.getItem('userImage') || MockImage} />
          </div>
            <span className='username'>{user?.attributes?.email}</span>
            <Dropdown
                overlay={menu}
                placement="bottomRight"
                trigger={['click']}
                visible={dropdownState}
            >
                <img className='dropdown-image' onClick={(e) => setDropdownState(!dropdownState)} width={24} src={dropdownState ? Bar : ArrowDown} />
            </Dropdown>
        </div>

    ): null
}
