import React from 'react'

import './styles.css'

export const CstomCard = ({ image, title, subtext }) => {
    return (
        <div className='card-layout'>
            <img className='image' height={130} src={image} />
            <span className='title'>{title}</span>
            <div className='divider'></div>
            <span className='sub-text'>{subtext}</span>
        </div>
    )
} 