import React  from "react";
import { useIdleTimer } from 'react-idle-timer';
import "./App.css";

// context
import { Provider } from "./Contexts/users";
//routes
import AppRouter from "./Navigation/Routes";

/** Ant design stylesheet */
import "antd/dist/antd.css";

import AWS from 'aws-sdk';
import Amplify, { Auth } from "aws-amplify";
import awsConfig from './aws-exports';
Auth.configure(awsConfig);
Amplify.configure(awsConfig);
function App() {

  AWS.config.update(
    {
      region: process.env.REACT_APP_AWS_REGION,
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      },
    });
  const onAction = () => {
    // console.log('user did something', e)
  }

  const onActive = () => {
    // console.log('user is active', e)
  }

  const onIdle = async () => {
      // console.log('get user upload status', localStorage.getItem('userUpload'));
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.log('currentAuthenticatedUser', user);
        Auth.signOut().then(() => {
          window.location.reload();
        });
      } catch (e) {
        console.log('User not logged in. Error: ', e, '. Action: do nothing');
      }

  }
  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: onIdle,
    onActive: onActive,
    onAction: onAction,
    debounce: 500
  })

  return (
    <Provider>
      {/* <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <button onClick={() => Auth.federatedSignIn({ provider: "Google" })}>
            sign in with google
          </button>
          <button onClick={() => Auth.federatedSignIn()}>sign in</button>
        </header>
      </div> */}
      <AppRouter />
    </Provider>
  );
}

export default App;
