import React from 'react'
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Form, Spin, Button, Col, Row, Input } from "antd";
import { GoogleOutlined, FacebookOutlined, UnlockOutlined } from "@ant-design/icons"
/** Assets */
import Logo from '../../Assets/Images/logo.black.svg'

import PasswordIcon from '../../Assets/Images/Password_Icon.svg'
import UsernameIcon from '../../Assets/Images/Email_icon.svg'

import './login.form.component.style.css'

const {} = Input

export const LogInFormComponent = ({ handleSubmit, loading }) => {

    const renderIcon= (icon)=>{
        return <img width='1em' height='1ex' className='pass-icon' src={icon}/>
    }

    return (
        <div className='main'>
            <img className="black-logo-width" src={Logo} />
            <div className="link info" >
                </div>
            {/* <span className='info'>Sign In with your username and password.</span> */}
            <div className="login-form">
                <div className="">
                    <Form onFinish={(values) => handleSubmit(values)} className="login-form">
                        Username
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please provide your username!'
                                }
                            ]}
                        >
                            <Input loading className='cred-input' prefix={renderIcon(UsernameIcon)}/>
                        </Form.Item>
                        Password
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please provide your password!'
                                }
                            ]}
                        >
                            <Input
                            type='password'
                                prefix={renderIcon(PasswordIcon)}
                                className='cred-input'
                            />
                        </Form.Item>
                        <Form.Item className="text-center mb-2">
                            <Link className="link" to="/forgot-password">
                            Forgot your password?
                </Link>
                        </Form.Item>
                        <Form.Item className="text-center mb-2">
                            <Button
                                type='submit'
                                className="signin-button"
                                loading={loading}
                                htmlType="submit"
                            >
                                Sign In
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}