import React from 'react'
import './service.name.component.style.css'

export const ServiceNameLayout = ({ subtext }) => {
    return (
        <div className='promo-container'>
            <p className='big-text-white'>
                Ground Truth
        </p>
            <p className='big-text-red'>
                Factory
        </p>
            {
                subtext ?
                    <p className='small-text-white'>
                    </p>
                    : null
            }

        </div>
    )
}
