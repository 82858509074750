import React from 'react'

import './styles.css'

export const BoxWrapper = ({children, className})=>{
    return (
        <div className={`wrapper ${className}`}>
            {children}
        </div>
    )
}