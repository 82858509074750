import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";

import { DATA_ANNOTATOR_USER_GROUP, DATA_VIEWER_USER_GROUP, DATA_UPLOADER_USER_GROUP } from "../Utils";
import PrivateLayout from "../Containers/PrivateLayout";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const authChecker = React.useCallback(() => {
    console.log(" Auth checker called");
    Auth.currentAuthenticatedUser().then((user) => {
      const groups = user?.signInUserSession?.accessToken?.payload["cognito:groups"];
      if (path === "/upload" && !groups.includes(DATA_UPLOADER_USER_GROUP)) {
        rest.history.push("/");
      }
      if (path === "/view" && !groups.includes(DATA_UPLOADER_USER_GROUP)) {
        rest.history.push("/");
      }
      if (path === "/annotate" && !groups.includes(DATA_ANNOTATOR_USER_GROUP)) {
        rest.history.push("/");
      }
    }).catch((e) => {
      console.log(e)
      rest.history.push("/login")
    });
  }, [path, rest.history]);

  useEffect(authChecker, [window.location.href]);

  const render = (props) => (true ? <PrivateLayout withoutLogo={rest.withoutLogo}><Component {...props} /></PrivateLayout> : null);

  return <Route path={path} render={render} {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export default withRouter(PrivateRoute);
