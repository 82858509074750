import React from 'react';
import { Row, Col } from "antd";
import styled from "styled-components";

const BorderColumn = styled.div`
  border-left: 3px solid #81CDBA;
  padding-left: 30px;
`;
const LayoutColumn = ({ left, right }) => {
  return (
    <Row gutter={30}>
      <Col span={8}>
        {left}
      </Col>
      <Col span={16}>
        <BorderColumn>
          {right}
        </BorderColumn>
      </Col>
    </Row>
  )
};

export default LayoutColumn;
