import React, { useState } from 'react';
import { Input } from 'antd';

const SearchImageIndex = (props) => {

    const [error, setError] = useState(false);

    const handleClick = (data) =>{
        let re = /^[1-9]$/;

        if(re.test(data)){
            setError(false);
        }else{
            setError(true);
        }

        props.onClick(data);
    }

    return (<div style={{ float: 'right' }}>
        <Input.Search
            placeholder="Index"
            allowClear
            onSearch={handleClick}
            style={error ? { width: 120, border: '1px solid red' } : { width: 120 } }
        />
    </div>)
};

export default SearchImageIndex;