import React, { useEffect, useState } from "react";
import DashBoard from "../../Containers/Dashboard";
import { Link, useHistory } from "react-router-dom";
import styled from 'styled-components';
import { Text, TextContainer } from "../Styled/Utils";
import { ReactComponent as BackIcon } from "../../Assets/Images/Back.svg";
import { ReactComponent as EditIcon } from "../../Assets/Images/edit_icon.svg";
import { Form, Input, Row, Col, Button, message, Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import MockImage from '../../Assets/Images/doctor_mock.svg'
import { createUser, updateUser } from '../../graphql/mutations'
import { getUser } from '../../graphql/queries'
import './settings.css';
import Loader from "../Loader";
import AWS from "aws-sdk";

const props = {
  name: 'file',
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const ProfileSection = styled.div``;

const getImage = async (Bucket, Key) => {
  const s3 = new AWS.S3({ apiVersion: '2020-11-09' });
    let params = {
      Bucket,
      Key
    };
    const url = await s3.getSignedUrl('getObject', params);
    return url;
};
const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  async function fetchUser(username, email) {
    setLoading(true);
    try {
      const userData = await API.graphql(graphqlOperation(getUser, {id: username}))
      const userDetail = userData.data.getUser;
      setLoading(false);
      if (!userDetail) {
        const newUser = await API.graphql(graphqlOperation(createUser,
          {input :{
            id: username,
            name: "",
            profileImage: "",
            email: email,
            firstName: "",
            lastName: "",
            title: ""
          }},));
        const fields = [
          { name: ['email'], value: newUser.data.createUser.email },
          { name: ['title'], value: "" },
          { name: ['firstName'], value: "" },
          { name: ['lastName'], value: "" },
          { name: ['id'], value: newUser.data.createUser.id }
        ];
        return Promise.resolve(fields);
      }
        const fields = [
          { name: ['email'], value: userDetail.email },
          { name: ['title'], value: userDetail.title },
          { name: ['firstName'], value: userDetail.firstName },
          { name: ['lastName'], value: userDetail.lastName },
          { name: ['id'], value: userDetail.id }
        ];
      if (userDetail.profileImage) {
        const url = await getImage(process.env.REACT_APP_AWS_BUCKET, userDetail.profileImage)
        setProfileImage(url)
        localStorage.setItem('userImage', url);
      }
      return Promise.resolve(fields);
    } catch (err) { console.log('error fetching userDetail', err); return [] }
  }
  const updateUs = (values) => {
    setLoading(true);
    console.log('values', values);
    const param = {...values};
    delete param.password;
    API.graphql(graphqlOperation(updateUser, {input : {
      ...param
    }}))
      .then(() => {
        message.success('Updated successfully!');
      })
      .catch()
      .finally(() => setLoading(false))
  }
const UserImage = styled.img`
width: 100px;
@media (max-width: 1200px) {
width: 60px
}
`;
  const Field = styled.div`
  display: flex;`;
  const FieldTitle = styled.span`
  margin-top: 6px;
  margin-right: 10px;
  `;
  const history = useHistory();
  const [user, setUser] = useState([]);
  const [id, setId] = useState('');
  useEffect(() => {
    Auth.currentAuthenticatedUser().then(async (d) => {
      const {username} = d;
      const {email} = d.attributes || {};
      const userDetail = await fetchUser(username, email)
      setId(username);
      setUser([...userDetail] );
    });
  }, []);
  const handleUpload = async (e)=> {
    if (e.file) {
      setLoading(true);
      const credentials = await Auth.currentUserInfo();
      const s3 = new AWS.S3({ apiVersion: '2020-11-09' });
      // call S3 to retrieve upload file to specified bucket
      let uploadParams = { Bucket: `${process.env.REACT_APP_AWS_BUCKET}/${credentials.username}`, Key: e.file.name, Body: e.file };
      s3.upload(uploadParams).on('httpUploadProgress', (evt) => {
      }).send(function(err, data) {
        if (err) {
          console.log('image uploaded err', err);
          return
        }
        API.graphql(graphqlOperation(updateUser, {input : {
            profileImage: data.key,
            id
          }}))
          .then(async () => {
            message.success('Updated successfully!');
            if (data.key) {
              const url = await getImage(process.env.REACT_APP_AWS_BUCKET, data.key)
              setProfileImage(url)
              localStorage.setItem('userImage', url);
              setLoading(false);
            }

          })
          .catch(() => setLoading(false))

        console.log('image uploaded', data);
      });
    }
  }
  return (
    <DashBoard
      pageTitle={<Link to="/annotate">
        <TextContainer><BackIcon /> <Text>Profile</Text></TextContainer></Link>}
    >
      {loading && <Loader />}
      <ProfileSection>
        {!loading && <UserImage src={profileImage || MockImage} alt="User Image" />}
        <Upload {...props}
                customRequest={(e) => handleUpload(e)}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
        <Form onFinish={updateUs} className="profile-form"
              fields={user}>
          <Row gutter={16}>
            <Col span={7}>
              <Field>
              <FieldTitle>Title</FieldTitle>
              <Form.Item
                name="title"
                className="input"
                rules={[
                  {
                    required: true,
                    message: 'Please provide your title!'
                  }
                ]}
              >
                <Input loading className='cred-input' value={user.title} />
              </Form.Item>
              </Field>
            </Col>
            <Col span={7}>
              <Field>
              <FieldTitle>Firstname</FieldTitle>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'Please provide your firstname!'
                  }
                ]}
              >
                <Input loading className='cred-input' placeholder={user.firstName} />
              </Form.Item>
              </Field>
            </Col>
            <Col span={7}>
<Field>
              <FieldTitle>Lastname</FieldTitle>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Please provide your lastname!'
                  }
                ]}
              >
                <Input loading className='cred-input' placeholder={user.lastName} />
              </Form.Item>
</Field>
            </Col>
          </Row>
          <hr />
          <Text>Account <EditIcon onClick={() => history.push('/create-password')} /></Text>

          <Row gutter={16}>
            <Col span={7}>
              <Field>
              <FieldTitle>Username</FieldTitle>
              <Form.Item
                name="id"
                className="input"
                rules={[
                  {
                    required: true,
                    message: 'Please provide your username!'
                  }
                ]}
              >
                <Input loading className='cred-input' value={user.id} placeholder={user.id} disabled />
              </Form.Item>
              </Field>
            </Col>
            <Col span={7}>
              <Field>
              <FieldTitle>Email</FieldTitle>
              <Form.Item
                name="email"
                className="input"
                rules={[
                  {
                    required: true,
                    message: 'Please provide your email!'
                  }
                ]}
              >
                <Input loading className='cred-input' value={user.email} placeholder={user.email} disabled />
              </Form.Item>
              </Field>
            </Col>
          </Row>
          <hr />
          <Text>Password <EditIcon onClick={() => history.push('/create-password')} /></Text>
          <Row>

            <Col span={7}>
              <Field>
              <FieldTitle>Password</FieldTitle>
              <Form.Item
                name="password"
                className="input"
              >
                <Input loading className='cred-input' placeholder="********" />
              </Form.Item>
              </Field>
            </Col>
          </Row>
          <Row>
            <Col>

              <Form.Item className="text-center mb-2">
                <Button
                  type='submit'
                  className="signin-button"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ProfileSection>
    </DashBoard>
  )
};

export default Profile;
