import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";

export const Context = createContext({});

export const Provider = (props) => {
  // Initial values are obtained from the props
  const {
    user: initialUsers = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
    // user: initialUsers = {},
    token: initialToken = localStorage.getItem("token"),
    children,
  } = props;
  // Use State to keep the values
  const [user, setUser] = useState(initialUsers);
  const [token, setToken] = useState(initialToken);

  React.useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else localStorage.removeItem("user");
    if (token) {
      localStorage.setItem("token", token);
    } else localStorage.removeItem("token");
  }, [token, user]);

  const logout = () => {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        localStorage.clear()
        setToken(null);
        setUser(null);
        Auth.signOut().then(window.location.reload());
      })
      .catch(() => window.location.reload());
  };

  // Make the context object:
  const usersContext = {
    user,
    setUser,
    token,
    setToken,
    logout,
  };

  // pass the value in provider and return
  return <Context.Provider value={usersContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

Provider.propTypes = {
  users: PropTypes.array,
  selectedUser: PropTypes.object,
};

Provider.defaultProps = {
  users: [],
  selectedUser: {},
};
