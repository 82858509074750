import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Table, Spin, notification } from 'antd';
import AWS from 'aws-sdk'
import "aws-sdk/clients/sagemaker";

import { LoadingOutlined } from '@ant-design/icons';

import LayoutColumn from "../LayoutColumn";
import ListAnnotateJobs from "../../Components/annotate/ListAnnotateJobs";
import { ReactComponent as BackIcon } from "../../Assets/Images/Back.svg";
import DashBoard from "../Dashboard";
import { TextContainer, Text } from "../../Components/Styled/Utils";
import { config } from "aws-sdk";
import useSageMakerJobs from "../../Components/annotate/useJobs";

const columns = [
  {
    title: 'Asset ID',
    dataIndex: ['name', 'jobName'],
    key: 'assetId',
    render: (name, jobName) => <Link to={`${jobName.jobName}/${jobName.name}?key=${jobName.key + 1}`}>{jobName.name}</Link>,
  },
  {
    title: 'Date Last Modified',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Number of Labels',
    dataIndex: 'label',
    key: 'label',
  },
];

const AnnotatorJobs = () => {

  const { region, credentials } = config;

  const options = {"region": region, "accessKeyId": credentials.accessKeyId,
    "secretAccessKey": credentials.secretAccessKey};

  const sagemaker = new AWS.SageMaker(options);

  const [assetMeta, setAssetMeta] = useState({});
  let [jobAssets, setJobAssets] = useState([]);
  const [selectedRowKeys, setselectedRowKeys] = useState([]);

  const jobParams = useParams();
  const [jobs, jobsLoading] = useSageMakerJobs();
  const [isLoading, setIsLoading] = useState(false);
  const [lastAsset, setLastAsset] = useState({});

  useEffect(() => {
    getJobAssets(jobParams);
  }, [jobParams]);

  const splitS3Url = (objectUrl) => {
    // Split bucket name and key from s3 object url.
    let s3UrlRe = /[s|S]3:\/\/(?<bucket>[^\/]*)\/(?<key>.*)/;
    return s3UrlRe.exec(objectUrl);
  }

  const getManifestData = async (manifestUrl) => {
    let response = await fetch(manifestUrl);
    if(response.status === 200){
      // format stream data in text format.
      return await response.text();
    }
    return []
  }

  const prepareData = (listUrl) => {
    let _listUrl = listUrl.split('{"source-ref":"');
    let urlData = [];

    _listUrl.map((list)=>{
      let _url = list.split('"}');
      if(_url.length === 2){
        urlData.push(_url[0]);
      }
      return list;
    });
    return urlData
  }

  const getS3SignedUrl = async (urlList) => {
    let resourseList = [];
    const s3 = new AWS.S3({ apiVersion: '2020-11-09' });

    urlList.map((url, index)=>{
      let bucketKey = splitS3Url(url);
      let params = {Bucket: bucketKey.groups.bucket, Key: bucketKey.groups.key};
      let signedUrl = s3.getSignedUrl('getObject', params);
      let _name = bucketKey.groups.key.split('/');

      resourseList.push({
        jobName: jobParams.job,
        key: index,
        name: _name.pop(),
        src: signedUrl
      });
      return url
    })

    setJobAssets(resourseList);
  }

  const getJobManifest = async (bucketUrl) => {

    const s3 = new AWS.S3({ apiVersion: '2020-11-09' });

    let s3ObjectUrl = splitS3Url(bucketUrl);
    let params = {Bucket: s3ObjectUrl.groups.bucket, Key: s3ObjectUrl.groups.key};
    let promise = s3.getSignedUrlPromise('getObject', params);

    await promise.then(async function(url) {
      let urlData = await getManifestData(url);
      let urlList = prepareData(urlData);
      await getS3SignedUrl(urlList);
    }, function(err){
      // TODO Handle Error
    }).catch(()=>{
      setJobAssets([]);
      notification.error({ message: "Resource not found." })}
    );
  }

  const getJobAssetMeta = async (outputBucket) =>{
    setIsLoading(true);
    setLastAsset({});
    const s3 = new AWS.S3({ apiVersion: '2020-11-09' });

    let jsonObjectUrl = splitS3Url(outputBucket);

    if(jsonObjectUrl){
      let jsonObjectKey = `${jsonObjectUrl.groups.key}${jobParams.job}/${jobParams.job}-meta.json`;

      let _params = {Bucket: jsonObjectUrl.groups.bucket, Key: jsonObjectKey};

      let jsonSignedUrl = s3.getSignedUrl('getObject', _params);

      await fetch(jsonSignedUrl)
      .then(response => response.json())
      .then(data => {
        setAssetMeta(data);
      })
      .catch(() => {
        setIsLoading(false);
        setAssetMeta({})
      });
    }
  }

  const getJobAssets = async (job) => {
    setIsLoading(true);
    let params = {
      LabelingJobName: job.job /* required */
    };

    await sagemaker.describeLabelingJob(params, async function(err, data) {
      if (err) {
        // TODO Handle Error
        setIsLoading(false);
        notification.error({ message: err.message })
      }
      else {
        await getJobManifest(data.InputConfig.DataSource.S3DataSource.ManifestS3Uri);
        await getJobAssetMeta(data.OutputConfig.S3OutputPath);
      }
    });
  }

  const filterDate = (date) => {
    date = new Date(date);
    let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleString('en-US', options).replaceAll("/", "-")
  }

  useEffect(() => {
    if(assetMeta.hasOwnProperty('lastAsset')){
      lastAsset[jobParams.job] = assetMeta.lastAsset.name;
      setLastAsset(lastAsset);
    }
    jobAssets = jobAssets.map((asset)=>{
      if(assetMeta.hasOwnProperty(asset.name)){
        asset.date = filterDate(assetMeta[asset.name].updatedAt);
        asset.type = "Submitted";
        asset.label = assetMeta[asset.name].numberOfLabels;
      }else{
        asset.date = 'NA';
        asset.type = 'In Progress';
        asset.label = 0;
      }
      return asset;
    })
    setIsLoading(false);
    setJobAssets(jobAssets);
  }, [assetMeta]);

  const selectRow = (record) => {
    let _selectedRowKeys = [...selectedRowKeys];
    if (_selectedRowKeys.indexOf(record.key) >= 0) {
      _selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
    } else {
      _selectedRowKeys.push(record.key);
    }
    setselectedRowKeys(_selectedRowKeys);
  }

  const onSelectedRowKeysChange = (selectedRowKeys) => {
    setselectedRowKeys(selectedRowKeys);
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 24, textAlign: 'center' }} spin />;

  return (
    <DashBoard
      pageTitle={
        <>
          <Link to="/annotate">
            <TextContainer>
              <BackIcon /> <Text>Project View</Text>
            </TextContainer>
          </Link>
        </>
      }>
      <LayoutColumn
        left={!jobsLoading ? <ListAnnotateJobs annotateJobs={jobs} lastAsset={lastAsset} activeJob={jobParams.job} /> : <Spin indicator={antIcon} />}
        right={jobAssets.length ?
          <Table size="small" dataSource={jobAssets} columns={columns}
            loading={isLoading}
            rowSelection={{selectedRowKeys, onChange: onSelectedRowKeysChange}}
            onRow={(record) => ({onClick: () => {selectRow(record);} })}
          /> :
        <Spin indicator={antIcon} />}
      />

    </DashBoard>
  )};

export default AnnotatorJobs;
