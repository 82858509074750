import React from 'react';
import Heading from "../Heading";
import { Col, Row } from "antd";
import Link from "../upload/Link";
import Annotate from '../../Assets/Images/Annotate_Icon.svg'
import View from '../../Assets/Images/View_Icon.svg'
import Upload from '../../Assets/Images/Upload_Icon.svg'
import HomeIcon from './Home_Icon.svg';
import styled from "styled-components";

const nav = {
  home: { icon: HomeIcon, title: 'Home', to: '/' },
  upload: { icon: Upload, title: 'Upload', to: '/upload' },
  view: { icon: View, title: 'Upload History', to: '/view' },
  annotate: { icon: Annotate, title: 'Annotate', to: '/annotate' },
};
const Container = styled.div`
height: 20vh;
`;
const QuickLinks = ({ links = [] }) => {
  return (
    <Container className="quickLinks">
      <Row>
      <Col span={24}>
        <Heading>Quick Links</Heading>
      </Col>
      {links.map(link => {
        const navLink = nav[link];
        return <Col span={8} key={link}>
          <Link icon={navLink.icon} to={navLink.to}>{navLink.title}</Link>
        </Col>
      })}
    </Row>
    </Container>
  )
};

export default QuickLinks;
