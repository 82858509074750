import React from "react";
import LayoutColumn from '../../Containers/LayoutColumn/';

/** App containers */
import DashBoard from "../../Containers/Dashboard";
import QuickLinks from "../QuickLinks";
import UploadStats from "../upload/UploadStats";
import Upload from "./Project_Icon.svg";
import RecentUploads from "./Total_Assets_Icon.svg";
import TotalUploads from "./Total_Assets_Labeled.svg";
import ListAnnotateJobs from "./ListAnnotateJobs";

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { Text } from "../Styled/Utils";
import useSageMakerJobs from "./useJobs";

const pageState = [
  {
    icon: Upload,
    count: 0,
    name: 'Active Projects'
  },
  {
    icon: RecentUploads,
    count: 0,
    name: 'Total Assets'
  },
  {
    icon: TotalUploads,
    count: 0,
    name: 'Total Assets Labeled'
  }
];


export default () => {
  const [jobs, jobsLoading] = useSageMakerJobs();

  const antIcon = <LoadingOutlined style={{ fontSize: 24, textAlign: 'center' }} spin />;
  return (
    <DashBoard
      pageTitle={<Text>Project Dashboard</Text>}
    >
      <LayoutColumn
        left={!jobsLoading ? <ListAnnotateJobs annotateJobs={jobs} /> : <Spin indicator={antIcon} />}
        right={(
          <>
            <UploadStats pageStats={pageState} title="Project Stats" stats={{ files: [] }} />
            <QuickLinks links={['upload', 'view', 'home']} />
          </>
        )}
      />
    </DashBoard>
  );
};
